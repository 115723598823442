import {createStyles} from '@mantine/core';

const useStyles = createStyles((theme) => ({
    mainSection: {
        margin: theme.spacing.xl,

        [`@media (max-width: ${theme.breakpoints.md}px)`]: {
            margin: theme.spacing.md,
        },
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            margin: 0,
        },
        paddingTop: theme.spacing.md,
        backgroundColor: theme.colorScheme === 'dark' ? theme.black : theme.white,
        borderRadius: '2rem',
        overflow: 'hidden',
        boxShadow: '0 0.5rem 1rem rgb(17 38 146 / 5%)',
        minHeight: '90vh'
    },
}));

type Props = {
    children?: React.ReactNode;
};

export default function AppContainer({children}: Props) {
    const {classes} = useStyles();

    return (
        <div className={classes.mainSection}>
            {children}
        </div>
    )
}