import {useRef} from 'react';
import {useMantineColorScheme} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {Avatar, Popover, ShellBar, ShellBarItem, Menu, MenuItem} from '@ui5/webcomponents-react';

import Notifications from './Notifications';

import "@ui5/webcomponents-icons/dist/general-leave-request.js";
import "@ui5/webcomponents-icons/dist/bed.js";
import "@ui5/webcomponents-icons/dist/bell.js";
import "@ui5/webcomponents-icons/dist/journey-arrive.js";
import "@ui5/webcomponents-icons/dist/action-settings.js";
import "@ui5/webcomponents-icons/dist/translate.js";

export default function MainShellBar() {
    const {i18n} = useTranslation();
    const {colorScheme, toggleColorScheme} = useMantineColorScheme();
    const dark = colorScheme === 'dark';

    const popoverRef = useRef(null);
    const menuRef = useRef(null);

    const handleShellBarItemClick = (e: any) => {
        // @ts-ignore
        popoverRef.current.showAt(e.detail.targetRef);
    };

    const handleProfileClick = (e: any) => {
        // @ts-ignore
        menuRef.current.showAt(e.detail.targetRef);
    }


    return (
        <>
            <ShellBar
                logo={<img alt="SAP Logo"
                           src="https://sap.github.io/ui5-webcomponents/assets/images/sap-logo-svg.svg"/>}
                primaryTitle="UI5 Web Components Template"
                secondaryTitle="in React.js"
                notificationsCount="10"
                profile={<Avatar><img alt='Avatar'
                                      src="https://media-exp1.licdn.com/dms/image/C4E03AQFmqJ5GsGVbhw/profile-displayphoto-shrink_800_800/0/1644248467685?e=1670457600&v=beta&t=S6mIPHGyCp57zY3_pdClKnSW-Gc0RRiTt57MBgzgFKo"/></Avatar>}
                onProfileClick={handleProfileClick}
                style={{padding: 0}}

            >
                <ShellBarItem
                    count={i18n.language !== 'EN' ? 'PL' : i18n.language}
                    icon='translate'
                    onClick={() => i18n.language !== 'EN' ? i18n.changeLanguage('EN') : i18n.changeLanguage('PL')}
                    text="Translate"
                />
                <ShellBarItem
                    count={colorScheme}
                    icon={dark ? 'bed' : 'general-leave-request'}
                    onClick={() => toggleColorScheme()}
                    text="Switch theme"
                />
                <ShellBarItem
                    count={'3'}
                    icon='bell'
                    onClick={handleShellBarItemClick}
                    text="Notifications"
                />
            </ShellBar>

            <Popover headerText="Notifications (3)" placementType='Bottom' style={{padding: 0}} ref={popoverRef}>
                <Notifications/>
            </Popover>

            <Menu
                ref={menuRef}
                onItemClick={function noRefCheck() {
                }}
            >
                <MenuItem
                    icon="action-settings"
                    startsSection
                    text="Settings"
                />
                <MenuItem
                    icon="journey-arrive"
                    text="Logout"
                />
            </Menu>
        </>
    )
}