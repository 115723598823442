import { ActionIcon, createStyles, Group } from '@mantine/core';
import { Title } from '@ui5/webcomponents-react';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
    footer: {
      borderTop: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
      }`,
    },
  
    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: `${theme.spacing.xl}px ${theme.spacing.xl}px`,
  
      [theme.fn.smallerThan('sm')]: {
        flexDirection: 'column',
      },
    },  
  }));

export default function Footer() {
    const { classes } = useStyles();

    return (
        <div className={classes.footer}>
        <div className={classes.inner}>
          <Title level='H5'>With 💙 to SAP @ Michał Majer</Title>

          <Group spacing="xs" position="right" noWrap>
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandTwitter size={18} stroke={1.5} />
            </ActionIcon>
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandYoutube size={18} stroke={1.5} />
            </ActionIcon>
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandInstagram size={18} stroke={1.5} />
            </ActionIcon>
          </Group>
        </div>
      </div>           
    )
}