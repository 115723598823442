import { Container, Group } from "@mantine/core";
import { Card, CardHeader  } from '@ui5/webcomponents-react';
import KPI, { KPIProps } from './KPI';

type KPIGroupProps = {
    kpis: KPIProps[]
}

type KPICardProps = {
    header?: string,
    subheader?: string,
    kpis: KPIProps[]
}


function KPIGroup({ kpis } : KPIGroupProps) {
    return (
        <Group position="center" spacing="xl" ml="xl" mb="xl" grow>
            {kpis.map(({ number, status, difference, footerText }, index) => <KPI key={index} number={number} status={status} difference={difference} footerText={footerText} /> )}
        </Group>
    )
}

export default function KPICard({ header, subheader, kpis }: KPICardProps) {
    return (
        <Container fluid>
            <Card header={<CardHeader status="" subtitleText={subheader} titleText={header} />}>
                <KPIGroup kpis={kpis} />
            </Card>
        </Container>
    )
}