import {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {ThemingParameters} from '@ui5/webcomponents-react-base';
import {Title} from '@ui5/webcomponents-react';
import {
    MantineProvider,
    MantineThemeOverride,
    ColorSchemeProvider,
    ColorScheme,
    createStyles,
    Container,
    Tabs
} from '@mantine/core';
import {NotificationsProvider} from '@mantine/notifications';

import AppContainer from './components/AppContainer';
import ShellBar from './components/ShellBar';
import Footer from './components/Footer';
import Dashboard from './pages/Dashboard';
import List from './pages/List';

const {setTheme} = require('@ui5/webcomponents-base/dist/config/Theme');
const fioriHorizonTheme: MantineThemeOverride = {
    //  white: ThemingParameters.sapAccentBackgroundColor
};

const useStyles = createStyles((theme) => ({
    header: {
        marginTop: '2rem'
    },

    shellBarMargin: {
        marginLeft: '3rem'
    },

    footer: {
        borderTop: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
        }`,
    }
}));

export default function App() {
    const {classes, cx} = useStyles();
    const { t } = useTranslation();
    const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
    const toggleColorScheme = (value?: ColorScheme) => {
        setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
        setTheme(colorScheme === 'dark' ? 'sap_horizon' : 'sap_horizon_dark')
    }
    const navigate = useNavigate();
    const {appTab} = useParams();

    return (
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <MantineProvider theme={{...fioriHorizonTheme, colorScheme: colorScheme}} withGlobalStyles withNormalizeCSS>
                <NotificationsProvider>
                    <AppContainer>
                        <ShellBar/>
                        <Title className={cx(classes.shellBarMargin, classes.header)}>{t('Good Morning')}!</Title>

                        <Tabs radius="md" defaultValue="dashboard" value={appTab}
                              onTabChange={(value) => navigate(`/app/${value}`)}>
                            <Tabs.List>
                                <Tabs.Tab value="dashboard" className={classes.shellBarMargin}>Dashboard</Tabs.Tab>
                                <Tabs.Tab value="table">Table</Tabs.Tab>
                                <Tabs.Tab value="messages">Messages</Tabs.Tab>
                            </Tabs.List>

                            <Container fluid pt="xl" pl="xs" pb="xl"
                                       style={{backgroundColor: ThemingParameters.sapBackgroundColor}}>
                                <Tabs.Panel value="dashboard"><Dashboard/></Tabs.Panel>
                                <Tabs.Panel value="table"><List/></Tabs.Panel>
                            </Container>
                        </Tabs>

                        <Footer/>
                    </AppContainer>
                </NotificationsProvider>
            </MantineProvider>
        </ColorSchemeProvider>
    );
}