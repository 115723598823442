import {Container} from '@mantine/core';
import {Card, CardHeader} from '@ui5/webcomponents-react';
import Table from "../components/Table"

export default function List() {
    return (
        <Container fluid>
            <Card header={<CardHeader titleText='List of users' subtitleText='Showing 1 to 9 of 9 entries'/>}>
                <Table/>
            </Card>
        </Container>
    )
}