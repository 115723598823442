import {
    Container,
    ActionIcon,
    Tooltip,
    Pagination,
    Table as MantineTable,
    Group,
} from '@mantine/core';
import {Badge, RatingIndicator} from '@ui5/webcomponents-react';
import {MicroBarChart, RadialChart} from '@ui5/webcomponents-react-charts';
import {IconSettings, IconTrash, IconPlayerPlay} from '@tabler/icons';
// interface TableProps {
//     data: {
//       title: string;
//       author: string;
//       year: number;
//       reviews: { positive: number; negative: number };
//     }[];
//   }

type PerformanceType = {
    value: number
}

const ActionBar = () => {
    return (
        <Group spacing="xs">
            <Tooltip label="Play"><ActionIcon variant="transparent" color="green"><IconPlayerPlay
                size={20}/></ActionIcon></Tooltip>
            <Tooltip label="Modify"><ActionIcon variant="transparent" color="orange"><IconSettings
                size={20}/></ActionIcon></Tooltip>
            <Tooltip label="Delete"><ActionIcon variant="transparent" color="red"><IconTrash
                size={20}/></ActionIcon></Tooltip>
        </Group>
    )
}

const Performance = ({value}: PerformanceType) => <MicroBarChart
    dataset={[
        {
            data: value,
            name: 'January'
        }
    ]}
    dimension={{
        accessor: 'name'
    }}
    measure={{
        accessor: 'data',
//   colors: [
//     '#f0ab00',
//     'var(--sapHighlightColor)'
//   ],
//   formatter: function noRefCheck(){},
        width: 16
    }}
    maxValue={100}
    onDataPointClick={function noRefCheck() {
    }}
    style={{
        height: '25px',
        width: '75%'
    }}
/>

const MicroRadio = ({value}: PerformanceType) =>
    <Group>
        <RadialChart value={value} style={{
            width: '50px',
            height: '50px'
        }} chartConfig={{
            innerRadius: '55%',
            margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            }
        }}/>
        {value} %
    </Group>

export default function Table() {
    return (
        <Container fluid>
            <MantineTable sx={{minWidth: 800}} verticalSpacing="xs" highlightOnHover>
                <thead>
                <tr>
                    <th>User</th>
                    <th>Status</th>
                    <th>Year</th>
                    {/* <th>Supervisor</th> */}
                    <th>Logs</th>
                    <th>Comment</th>
                    <th>Rate</th>
                    <th>Overhours</th>
                    <th>Performance</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>John Cena</td>
                    <td><Badge colorScheme='8'>Active</Badge></td>
                    <td>2020</td>
                    {/* <td><Badge sx={{ paddingLeft: 0 }} size="lg" radius="xl" color="blue" leftSection={avatar}>Michał Majer</Badge></td> */}
                    <td>2231+</td>
                    <td>Fiori Graph</td>
                    <td><RatingIndicator value={5} readonly/></td>
                    <td><MicroRadio value={Math.floor(Math.random() * 100)}/></td>
                    <td><Performance value={Math.floor(Math.random() * 100)}/></td>
                    <td><ActionBar/></td>
                </tr>
                <tr>
                    <td>Typescript</td>
                    <td><Badge colorScheme='8'>Active</Badge></td>
                    <td>2020</td>
                    {/* <td><Badge sx={{ paddingLeft: 0 }} size="lg" radius="xl" color="blue" leftSection={avatar}>Michał Majer</Badge></td> */}
                    <td>2231+</td>
                    <td>Fiori Graph</td>
                    <td><RatingIndicator value={4} readonly/></td>
                    <td><MicroRadio value={Math.floor(Math.random() * 100)}/></td>
                    <td><Performance value={Math.floor(Math.random() * 100)}/></td>
                    <td><ActionBar/></td>
                </tr>
                <tr>
                    <td>Typescript</td>
                    <td><Badge colorScheme='3'>Inactive</Badge></td>
                    <td>2020</td>
                    {/* <td><Badge sx={{ paddingLeft: 0 }} size="lg" radius="xl" color="blue" leftSection={avatar}>Michał Majer</Badge></td> */}
                    <td>2231+</td>
                    <td>Fiori Graph</td>
                    <td><RatingIndicator value={0} readonly/></td>
                    <td><MicroRadio value={Math.floor(Math.random() * 100)}/></td>
                    <td><Performance value={Math.floor(Math.random() * 100)}/></td>
                    <td><ActionBar/></td>
                </tr>
                <tr>
                    <td>John Cena</td>
                    <td><Badge colorScheme='8'>Active</Badge></td>
                    <td>2020</td>
                    {/* <td><Badge sx={{ paddingLeft: 0 }} size="lg" radius="xl" color="blue" leftSection={avatar}>Michał Majer</Badge></td> */}
                    <td>2231+</td>
                    <td>Fiori Graph</td>
                    <td><RatingIndicator value={5} readonly/></td>
                    <td><MicroRadio value={Math.floor(Math.random() * 100)}/></td>
                    <td><Performance value={Math.floor(Math.random() * 100)}/></td>
                    <td><ActionBar/></td>
                </tr>
                <tr>
                    <td>Typescript</td>
                    <td><Badge colorScheme='8'>Active</Badge></td>
                    <td>2020</td>
                    {/* <td><Badge sx={{ paddingLeft: 0 }} size="lg" radius="xl" color="blue" leftSection={avatar}>Michał Majer</Badge></td> */}
                    <td>2231+</td>
                    <td>Fiori Graph</td>
                    <td><RatingIndicator value={4} readonly/></td>
                    <td><MicroRadio value={Math.floor(Math.random() * 100)}/></td>
                    <td><Performance value={Math.floor(Math.random() * 100)}/></td>
                    <td><ActionBar/></td>
                </tr>
                <tr>
                    <td>Typescript</td>
                    <td><Badge colorScheme='3'>Inactive</Badge></td>
                    <td>2020</td>
                    {/* <td><Badge sx={{ paddingLeft: 0 }} size="lg" radius="xl" color="blue" leftSection={avatar}>Michał Majer</Badge></td> */}
                    <td>2231+</td>
                    <td>Fiori Graph</td>
                    <td><RatingIndicator value={0} readonly/></td>
                    <td><MicroRadio value={Math.floor(Math.random() * 100)}/></td>
                    <td><Performance value={Math.floor(Math.random() * 100)}/></td>
                    <td><ActionBar/></td>
                </tr>
                <tr>
                    <td>John Cena</td>
                    <td><Badge colorScheme='8'>Active</Badge></td>
                    <td>2020</td>
                    {/* <td><Badge sx={{ paddingLeft: 0 }} size="lg" radius="xl" color="blue" leftSection={avatar}>Michał Majer</Badge></td> */}
                    <td>2231+</td>
                    <td>Fiori Graph</td>
                    <td><RatingIndicator value={5} readonly/></td>
                    <td><MicroRadio value={Math.floor(Math.random() * 100)}/></td>
                    <td><Performance value={Math.floor(Math.random() * 100)}/></td>
                    <td><ActionBar/></td>
                </tr>
                <tr>
                    <td>Typescript</td>
                    <td><Badge colorScheme='8'>Active</Badge></td>
                    <td>2020</td>
                    {/* <td><Badge sx={{ paddingLeft: 0 }} size="lg" radius="xl" color="blue" leftSection={avatar}>Michał Majer</Badge></td> */}
                    <td>2231+</td>
                    <td>Fiori Graph</td>
                    <td><RatingIndicator value={4} readonly/></td>
                    <td><MicroRadio value={Math.floor(Math.random() * 100)}/></td>
                    <td><Performance value={Math.floor(Math.random() * 100)}/></td>
                    <td><ActionBar/></td>
                </tr>
                <tr>
                    <td>Typescript</td>
                    <td><Badge colorScheme='3'>Inactive</Badge></td>
                    <td>2020</td>
                    {/* <td><Badge sx={{ paddingLeft: 0 }} size="lg" radius="xl" color="blue" leftSection={avatar}>Michał Majer</Badge></td> */}
                    <td>2231+</td>
                    <td>Fiori Graph</td>
                    <td><RatingIndicator value={0} readonly/></td>
                    <td><MicroRadio value={Math.floor(Math.random() * 100)}/></td>
                    <td><Performance value={Math.floor(Math.random() * 100)}/></td>
                    <td><ActionBar/></td>
                </tr>
                </tbody>
            </MantineTable>
            <Pagination position="right" total={10} pt={15} pb={15}/></Container>
    )
}