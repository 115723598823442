import {createStyles, Button as MantineButton, Container, Group, Divider} from '@mantine/core';
import {showNotification, updateNotification} from '@mantine/notifications';
import {
    Badge,
    Title,
    StandardListItem,
    Card,
    CardHeader,
    Icon,
    List,
    Text as FioriText,
    IllustratedMessage
} from '@ui5/webcomponents-react';
import {IconCheck} from '@tabler/icons';
import "@ui5/webcomponents-icons/dist/employee.js"
import "@ui5/webcomponents-icons/dist/search.js"
import "@ui5/webcomponents-icons/dist/add.js"
import "@ui5/webcomponents-icons/dist/inspect.js"
import "@ui5/webcomponents-icons/dist/jam.js"
import "@ui5/webcomponents-icons/dist/document-text.js"
import "@ui5/webcomponents-fiori/dist/illustrations/EmptyList.js"

import KPICard from '../components/KPICard';
import {KPIStatus, KPIProps} from '../components/KPI';

const useStyles = createStyles((theme) => ({
    subHeader: {
        margin: '2rem 2rem 0.5rem 2rem'
    },
}));

export default function Dashboard() {
    const {classes, cx} = useStyles();

    const kpis: KPIProps[] = [
        {number: 5, status: KPIStatus.positive, difference: '5%', footerText: 'Total sales!'},
        {number: 20, status: KPIStatus.positive, difference: '15%', footerText: 'Total profit'},
        {number: 120, status: KPIStatus.warning, footerText: 'Revenue'},
        {number: 12, status: KPIStatus.negative, difference: '25%', footerText: 'Nice Stats'},
        {number: 66, status: KPIStatus.neutral, footerText: 'Transfers done'},
    ];

    return (
        <Container fluid>
            <KPICard header='Daily stats' subheader='Direct Reports' kpis={kpis}/>

            <Title level='H3' className={cx(classes.subHeader)}>Messages</Title>
            <Container fluid>
                <Card style={{
                    width: '400px'
                }}
                      className="ui5-content-density-compact"
                >
                    <Container py='xs'>
                        <IllustratedMessage name="EmptyList" size="Spot"/>
                    </Container>
                </Card>
            </Container>

            <Title level='H3' className={cx(classes.subHeader)}>Operations</Title>
            <Container fluid>
                <Group position="left" spacing="xl" mb="xl">
                    <Card
                        header={<CardHeader titleText="Quick Actions"/>}
                        style={{
                            width: '300px'
                        }}
                        className="ui5-content-density-compact"
                    >
                        <List>
                            <StandardListItem icon="search" iconEnd>
                                Search
                            </StandardListItem>
                            <StandardListItem icon="add" iconEnd>
                                Create new
                            </StandardListItem>
                            <StandardListItem icon="inspect" iconEnd>
                                Inspect logs
                            </StandardListItem>
                            <StandardListItem icon="jam" iconEnd>
                                Social profile
                            </StandardListItem>
                            <StandardListItem icon="document-text" iconEnd>
                                Documentation
                            </StandardListItem>
                        </List>
                    </Card>

                    <Card
                        header={<CardHeader titleText="Last run of database query ZQUERY failed"/>}
                        style={{
                            width: '400px'
                        }}
                        className="ui5-content-density-compact"
                    >
                        <Container py='xs'>
                            <Badge icon={<Icon name="employee"/>} colorScheme='3'>
                                Database Error
                            </Badge>
                            <FioriText>
                                Error 201: Something went wrong, please check the output of the database console.
                            </FioriText>
                            <Divider my="lg"/>
                            <MantineButton color='yellow' variant="light" onClick={() => {
                                showNotification({
                                    id: 'load-data',
                                    loading: true,
                                    title: 'Loading your data',
                                    message: 'Data will be loaded in 3 seconds, you cannot close this yet',
                                    autoClose: false,
                                    disallowClose: true,
                                });

                                setTimeout(() => {
                                    updateNotification({
                                        id: 'load-data',
                                        color: 'teal',
                                        title: 'Data was loaded',
                                        message: 'Notification will close in 2 seconds, you can close this notification now',
                                        icon: <IconCheck size={16}/>,
                                        autoClose: 2000,
                                    });
                                }, 3000);
                            }}>
                                Check logs
                            </MantineButton>
                        </Container>
                    </Card>

                    <Card
                        header={<CardHeader titleText="Last run of database query ZQUERY failed"/>}
                        style={{
                            width: '400px'
                        }}
                        className="ui5-content-density-compact"
                    >
                        <Container py='xs'>
                            <Badge icon={<Icon name="employee"/>} colorScheme='8'>
                                Run Completed
                            </Badge>
                            <FioriText>
                                Success: Your procedure_transfer_data_to_datalake run have been completed, 3 min ago.
                            </FioriText>
                            <Divider my="lg"/>
                            <MantineButton color='green' variant="light" onClick={() =>
                                showNotification({
                                    title: 'Default notification',
                                    message: 'Hey there, your code is awesome! 🚀',
                                    color: 'green'
                                })
                            }>
                                Thanks!
                            </MantineButton>
                        </Container>
                    </Card>

                    <Card
                        header={<CardHeader titleText="Last run of database query ZQUERY failed"/>}
                        style={{
                            width: '400px'
                        }}
                        className="ui5-content-density-compact"
                    >
                        <Container py='xs'>
                            <Badge icon={<Icon name="employee"/>} colorScheme='5'>
                                Incoming Request
                            </Badge>
                            <FioriText>
                                User michal.majer requested the data from the dataservice: materials
                            </FioriText>
                            <Divider my="lg"/>
                            <MantineButton variant="light" onClick={() =>
                                showNotification({
                                    title: 'Default notification',
                                    message: 'Hey there, your code is awesome! 🚀',
                                    color: 'red'
                                })
                            }>
                                Clear
                            </MantineButton>
                        </Container>
                    </Card>
                </Group>
            </Container></Container>
    )
}