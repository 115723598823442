import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@ui5/webcomponents-react';
import '@ui5/webcomponents/dist/Assets';
import '@ui5/webcomponents-fiori/dist/Assets';
import './index.css';
import './i18n';

const { setTheme } = require('@ui5/webcomponents-base/dist/config/Theme');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

setTheme('sap_horizon');

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <></>
      },
      {
        path: "app",
        element: <></>
      },
      {
        path: "app/:appTab",
        element: <></>
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();