import {List, NotificationListItem, Label, Priority, Icon} from "@ui5/webcomponents-react";
import {format, formatDistanceToNow} from 'date-fns'

import "@ui5/webcomponents-icons/dist/past.js";

type NotificationProps = {
    text: string;
    description: string;
    priority: Priority | keyof typeof Priority;
    createdAt: number;
    read: boolean;
}

export default function Notifications() {

    const notifications: NotificationProps[] = [
        {
            text: "Migration task completed",
            description: "Short description",
            priority: 'Low',
            createdAt: 1665694348000,
            read: false,
        },
        {
            text: "Evaluation taks ended with warnings",
            description: "Short description",
            priority: 'Medium',
            createdAt: 1665665298000,
            read: true,
        },
        {
            text: "Important task ended with errors!",
            description: "Lorem impus dor mit set",
            priority: 'High',
            createdAt: 1665578898000,
            read: true,
        }
    ]

    return (
        <List>
            {notifications.map(({text, description, priority, createdAt, read}: NotificationProps, index : number) =>
                <NotificationListItem
                    key={index}
                    showClose
                    read={read}
                    titleText={text}
                    footnotes={<>
                        <Icon name="past"/>
                        <Label>{format(createdAt, 'dd.MM.yyyy hh:mm:ss')}</Label>
                        <Label>{formatDistanceToNow(createdAt, {addSuffix: true})}</Label>
                    </>}
                    priority={priority}
                >
                    {description}
                </NotificationListItem>)
            }
        </List>
    );
}