import {createStyles, Text, Group, Center} from '@mantine/core';
import {IconArrowUpRight, IconArrowDownRight} from '@tabler/icons';

const useStyles = createStyles((theme) => ({
    stats: {
        marginLeft: theme.spacing.xl,
        marginRight: theme.spacing.xl,
        minWidth: '200px'
    },

    value: {
        fontSize: 133,
        fontWeight: 700,
        lineHeight: 1,
        [`@media (max-width: ${theme.breakpoints.md}px)`]: {
            fontSize: 88,
        },
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            fontSize: 66,
        },
    },

    diff: {
        lineHeight: 1,
        display: 'flex',
        alignItems: 'center',
    },
}));

export enum KPIStatus {
    negative = 'negative',
    warning = 'warning',
    neutral = 'neutral',
    positive = 'positive',
}

export type KPIProps = {
    number: number | string,
    status: KPIStatus,
    difference?: number | string,
    footerText?: string
}


export default function KPI({number, status, difference, footerText}: KPIProps) {
    const {classes} = useStyles();

    const colorByKPIStatus = ({status}: { status: KPIStatus }) => {
        switch (status) {
            case KPIStatus.positive:
                return 'green';
            case KPIStatus.warning:
                return 'orange';
            case KPIStatus.negative:
                return 'red';
            default:
                return ''
        }
    }

    const IconByKPIStatus = ({status}: { status: KPIStatus }) => {
        switch (status) {
            case KPIStatus.positive:
                return <IconArrowUpRight size={16} stroke={1.5}/>
            case KPIStatus.negative:
                return <IconArrowDownRight size={16} stroke={1.5}/>
            default:
                return <></>
        }
    }

    return (
        <div className={classes.stats}>
            <Group align="flex-end" position="center" spacing="xs">
                <Text className={classes.value} color={colorByKPIStatus({status})}>{number}</Text>
                <Text
                    color={colorByKPIStatus({status})}
                    size="sm"
                    weight={500}
                    className={classes.diff}
                >
                    {difference}
                    <IconByKPIStatus status={status}/>
                </Text>
            </Group>

            <Center>
                <Text size="xs" color="dimmed" mt={7}>
                    {footerText}
                </Text>
            </Center>
        </div>
    )
}